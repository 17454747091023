<template>
  <div class="version-intro">
    <h1>版本信息</h1>
    <div class="version-block">
      <div class="version-line" v-for="item in versionList" :key="item.id">
        <h2>{{item.name}}</h2>
        <div class="line">
          <div class="page" v-html="item.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from 'vue';
import { getHomeVersion } from 'network/getData'
export default {
  name: "VersionIntro",
  setup() {
    let versionList = ref([]);
    const $api = getCurrentInstance().appContext.config.globalProperties.$api;

    const getDataFn = () => {
      getHomeVersion().then(data => {
        versionList.value = data;
      })
    };

    onMounted(() => {
      getDataFn();
    })

    return {
      versionList
    }
  },
}
</script>

<style lang="scss" scoped>
.version-intro {
  width: 100%;
  float: left;
  padding: 42px 64px;
  text-align: center;

  h1 {
    width: 100%;
    text-align: center;
    font-size: 32px;
    line-height: 1;
    color: #111;
    margin-bottom: 30px;
  }
}

.version-block {
  width: 100%;
  max-width: 950px;
  margin: 10px auto;
  background: rgba(0, 0, 0, 0.03);
  padding: 0 60px;
  border-radius: 8px;
  display: inline-block;
  text-align: left;

  .version-line {
    width: 100%;
    float: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 30px 0;

    &:last-child {
      border-bottom: 0;
    }
  }

  h2 {
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #111;
    float: left;
    margin-bottom: 10px;
  }

  .page {
    width: 100%;
    word-break: break-all;
    line-height: 1.3;
    color: #333;
    float: left;
    margin-bottom: 5px;
    font-size: 14px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>