<template>
  <div class="index-scroll">
    <swiper :modules="modules"
            :slides-per-view="swiperOption.slidesPerView"
            :space-between="swiperOption.spaceBetween"
            :loop="swiperOption.loop"
            :speed="swiperOption.speed"
            :autoplay="swiperOption.autoplay"
            :grab-cursor="swiperOption.grabCursor"
            @swiper="onSwiper"
            style="background:#000"
            @slideChange="onSlideChange">
      <swiper-slide>
        <div class="my-swiper">
          <img src="~assets/images/swiper/swiper02.jpeg"
               alt=""
               class="swiper-bg" />
          <h1>蓝色星球可视化数据大屏平台</h1>
          <h4>创建您的第一个可视化大屏</h4>
          <div class="swiper-btn" @click="experience()">立即体验</div>
          <img src="~assets/images/swiper/dp-01.png"
               alt=""
               class="dp">
        </div>
      </swiper-slide>
    </swiper>
    <div class="frame">
      <version-intro></version-intro>
    </div>
  </div>
</template>

<script>

import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue.js';
import VersionIntro from './ChildComps/VersionIntro.vue'
import 'swiper/swiper.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';
export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
    VersionIntro,
  },
  data () {
    return {
      modules: [
        Autoplay
      ],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 5,
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: true,
          disableOnInteraction: false
        },
        speed: 800,
        grabCursor: true
      },
    }
  },
  created () {
    if (this.$route.query.token) {
      const self = this;
      this.$store.dispatch("setToken", self.$route.query.token).then(() => {
        self.$emit('loginClose');
        self.$router.push('Mgt');
      });
    }
  },
  mounted() {
    if(this.$store.state.user && this.$store.state.user.userInfo) 
      this.$bus.emit('isLogin', true);
  },
  methods: {
    onSwiper: (swiper) => {
      // console.log(swiper);
    },
    onSlideChange: () => {
      // console.log('slide change');
    },
    experience() {
      if(this.$store.state.user && this.$store.state.user.userInfo) 
        this.$router.push('Mgt');
      else 
        this.$bus.emit('showLogin', true);
    }
  }
}
</script>

<style lang="scss" scoped>
.index-scroll {
  width: 100%;
  float: left;

  .swiper-slide {
    margin: 0;
  }
}
</style>

<style lang="scss">
@mixin gradualLine($color, $dir) {
  background: transparent;
  background-image: -webkit-linear-gradient(to $dir, $color);
  background-image: -moz-linear-gradient(to $dir, $color);
  background-image: -o-linear-gradient(to $dir, $color);
  background-image: linear-gradient(to $dir, $color);
}

.my-swiper {
  width: 100vw;
  height: 100vh;

  .swiper-bg {
    width: 100%;
    height: 100%;
    position: relative;
    float: left;
    z-index: 1;
  }

  h1,
  h4,
  .swiper-btn {
    position: absolute;
    top: 30%;
    left: 10%;
    z-index: 5;
  }

  h1 {
    font-size: 48px;
    margin: -64px 0 0;
    color: #fff;
  }

  h4 {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
  }

  .swiper-btn {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin: 64px 0 0;
    $col: rgba(0, 130, 255, 1) 0%, rgba(30, 170, 255, 1) 100%;
    @include gradualLine($col, right);
    border-radius: 20px;
    color: #fff;
    pointer-events: auto;
    cursor: pointer;

    &:hover {
      $col: rgba(30, 170, 255, 1) 0%, rgba(115, 200, 255, 1) 100%;
      @include gradualLine($col, right);
    }
  }

  .dp {
    height: 50vh;
    position: absolute;
    right: 10%;
    margin: -25vh 0;
    top: 50%;
    z-index: 4;
    pointer-events: none;
  }
}

.swiper-slide-active {
  .my-swiper {
    h1 {
      animation: flipInX;
      animation-delay: 0.4s;
      animation-duration: 1.2s;
    }
    h4 {
      animation: flipInX;
      animation-delay: 0.6s;
      animation-duration: 1.2s;
    }
    .swiper-btn {
      animation: bounceIn;
      animation-delay: 0.8s;
      animation-duration: 1.2s;
    }
  }
}
</style>